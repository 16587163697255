<template>
    <div class="serch-technical-services">
        <itc-ts-searcher :searchFunction="searchTechnicalService" class="my-4"></itc-ts-searcher>
        <transition name="fade" mode="out-in">
            <keep-alive>
                <component
                    :is="actualComponent"
                    :technicalServices="technicalServices"
                    :technicalService="technicalService"
                    :selectTechnicalService="selectTechnicalService"
                    @click-on-back-button="actualComponent = 'itc-ts-table'">
                </component>
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import axios from 'axios'

import TechnicalServiceSearcher from '@/components/localshop/technical_service/TechnicalServiceSearcher'
import TechnicalServiceTable from '@/components/localshop/technical_service/TechnicalServiceTable'
import TechnicalServiceControlPanel from '@/components/localshop/technical_service/TechnicalServiceControlPanel'

export default {
 data(){
     return {
         technicalServices: [],
         technicalService: {},
         actualComponent: 'itc-ts-table',
     }
 },
 methods: {
     selectTechnicalService(ts){
         this.technicalService = ts;
         this.actualComponent = 'itc-ts-control-panel'
     },
     async searchTechnicalService(searcher, type){
        const resp = await axios.get(`/technical-service/${type}?searcher=${searcher}&mode=complete`)
        this.actualComponent = 'itc-ts-table'
        this.technicalServices = resp.data
     },
 },
 components: {
    'itc-ts-table': TechnicalServiceTable,
    'itc-ts-searcher': TechnicalServiceSearcher,
    'itc-ts-control-panel': TechnicalServiceControlPanel
 },
 async created(){
     const resp = await axios.get('/technical-service/last');
     this.technicalServices = resp.data
 }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>