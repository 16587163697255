<template>
    <div class="orders-list custom-shadow">
        <table id="tablePreview" class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#Servicio</th>
                    <th>Fecha</th>
                    <th>Apellido</th>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                <tr  v-if="technicalServices.length == 0">
                    <td colspan="8" class="text-center">
                        <h1>Sin servicio técnicos</h1>
                    </td>
                </tr>
                <tr
                    v-for="(ts, i) in technicalServices" 
                    :key="i" 
                    class="order-item"
                    @click="selectTechnicalService(ts)">
                    <th scope="row">{{ts.serviceNumber}}</th>
                    <td>{{ts.date}}</td>
                    <td>{{ts.user.surname}}</td>
                    <td>{{ts.user.name}}</td>
                    <td>{{ts.itemType.name}}</td>
                    <td v-if="ts.cancellation.isCancelled"><h6 class="text-danger">Cancelado</h6></td>
                    <td v-else>{{ts.history[ts.history.length - 1].name | filterTsStatus}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import * as utils from '@/assets/utils/utils.js'

export default {
    props: {
        technicalServices: Array,
        selectTechnicalService: Function
    },
    filters: {
        filterTsStatus(name){
            return utils.filterTechnicalServiceStatus(name)
        },
    }
}
</script>

<style lang="scss" scoped>
.order-item:hover{
    background-color: lightgreen;
}
</style>