<template>
  <div class="admin-sidebar text-white text-center">
    <itc-sidebar>
        <a @click="changePage('itc-search-technical-service')" class="sidebar-item w-100 p-3 set-pointer">
          Buscar
          <i class="sidebar-icon fas fa-search"></i>
        </a>
        <a @click="changePage('itc-generate-technical-service')" class="sidebar-item w-100 p-3 set-pointer">
          Generar
          <i class="sidebar-icon fas fa-tools"></i>
        </a>
        <router-link tag="button" class="btn btn-warning m-2 back-button" to="/localshop/menu">Volver</router-link>
    </itc-sidebar>
  </div>
</template>

<script>
import axios from 'axios'

import SideBar from '@/components/mix/SideBar'

export default {
    components: {
        'itc-sidebar': SideBar
    },
    props: {
      changePage: Function
    }
}
</script>

<style lang="scss" scoped>
.back-button{
    position: fixed;
    left: 0;
    bottom: 0
}

.sidebar-item{
  display: inline-block;
  color: #fff;
  font-size: 15px;
  padding-top: 13px;
  border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.9)
}

i.sidebar-icon {
  margin: 0px 20px 0px 20px
}
</style>