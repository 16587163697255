<template>
    <div class="ts-control-panel">
        <itc-modal :showModal="showModal.budget">
            <div slot="header">
                <h4>Presupuesto</h4>
            </div>
                <div slot="body">
                    <form id="budgetForm" @submit.prevent="submitBudget">
                        <label>Ingrese el presupuesto</label>
                        <input name="budget" class="form-control" type="number">
                    </form>
                </div>
                <div slot="footer">
                    <div class="row">
                        <div class="col">
                            <button type="submit" form="budgetForm" class="modal-default-button btn btn-success float-right mx-2">OK</button>
                            <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.budget = false">Cerrar</button>
                        </div>
                    </div>
                </div>
        </itc-modal>
        <itc-modal :showModal="showModal.cancelTs">
            <div slot="header">
                <h4>Cancelar servicio técnico</h4>
            </div>
                <div slot="body">
                    <h5>¿Seguro que quieres cancelar el servicio técnico?</h5>
                    <form id="cancelForm" @submit.prevent="cancelTechnicalService">
                        <label>Motivo</label>
                        <input name="reason" class="form-control" type="text" required>
                    </form>
                </div>
                <div slot="footer">
                    <div class="row">
                        <div class="col">
                            <button type="submit" form="cancelForm" class="modal-default-button btn btn-success float-right mx-2">OK</button>
                            <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.cancelTs = false">Cerrar</button>
                        </div>
                    </div>
                </div>
        </itc-modal>
        <itc-card color="#343a40" class="custom-shadow">
            <div slot="header" class="text-white">
                <div class="row">
                    <div class="col-4">
                        <button 
                            @click="$emit('click-on-back-button')"
                            class="btn btn-info"> Volver
                        </button>
                    </div>
                    <div class="text-center col">
                        <h3>Servicio técnico #{{technicalService.serviceNumber}}</h3>
                    </div>
                    <div class="col-4">
                        <button 
                            :disabled="technicalService.cancellation.isCancelled || hasStatus('finished')" 
                            @click="showModal.cancelTs = true"
                            class="btn btn-danger float-right mx-2">Terminar</button>
                        <button 
                            :disabled="(!hasStatus('in-repair') || hasStatus('retire') || hasStatus('finished')) || technicalService.cancellation.isCancelled"
                            @click="repairTechnicalService"
                            class="btn btn-warning float-right mx-2">Reparado</button>
                        <button class="btn btn-primary  float-right mx-2" disabled>Notificar</button>
                        <button @click="printOrder" class="btn btn-success float-right mx-2">Imprimir</button>
                    </div>
                </div>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-8">
                        <div class="p-3">
                            <h4>Datos generales</h4>
                            <hr>
                            <ul>
                                <li v-if="technicalService.user"><strong>Cliente: </strong>{{technicalService.user.name}} {{technicalService.user.surname}}</li>
                                <li v-else><strong>Cliente: </strong>Sin cliente</li>
                                <li><strong>Fecha de ingreso: </strong>{{technicalService.date}}</li>
                                <li>
                                    <strong>Presupuesto: </strong>{{technicalService.budget ? `$${technicalService.budget}`: 'Sin presupuestar'}}
                                    <i 
                                        v-if="!technicalService.cancellation.isCancelled && !hasStatus('finished')"
                                        @click="showModal.budget = true" class="mx-2 set-pointer far fa-edit"></i>
                                    <template v-if="technicalService.history[technicalService.history.length - 1].name == 'budgeted'">
                                        |
                                        <strong
                                            @click="confirmBudget"
                                            class="mx-2 text-primary set-pointer">Aceptar</strong>
                                    </template>
                                </li>
                                <li><strong>Seña: </strong>{{technicalService.downPayment ? `$${technicalService.downPayment}` : 'Sin seña'}}</li>
                                <li :class="{'text-danger': technicalService.cancellation.isCancelled}"><strong>Estado: </strong>
                                    <template v-if="technicalService.cancellation.isCancelled">Cancelado. Motivo: {{technicalService.cancellation.reason}}</template>
                                    <template v-else>{{technicalService.history[technicalService.history.length - 1].name | filterTsStatus}}</template>
                                </li>
                                <li><strong>Técnico: </strong>{{technicalService.technician.name}}</li>
                            </ul>
                            <h4>Datos del item</h4>
                            <hr>
                            <ul>
                                <li><strong>Tipo: </strong>{{technicalService.itemType.name}}</li>
                                <li><strong>Descripcion: </strong>{{technicalService.description}}</li>
                                <li><strong>Falla: </strong>{{technicalService.failure}}</li>
                                <li><strong>Número de serie: </strong>{{technicalService.serial}}</li>
                                <li><strong>Observaciones: </strong>{{technicalService.observs}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-4">
                        <itc-card  color="#fff" class="custom-shadow px-2">
                            <div slot="header">
                                <h4>Timeline
                                    <strong v-if="technicalService.cancellation.isCancelled" class="cancellation-box text-danger mx-2">
                                        Cancelado  {{technicalService.cancellation.date}}
                                    </strong>
                                </h4>
                            </div>
                            <div slot="body">
                                <ul class="timeline">
                                    <li 
                                        v-for="(status, key, i) in tsStatus" :key="i" 
                                        :class="getTimelineClass(key)">
                                        <h6 
                                            :class="getTextColor(key)">
                                                {{key | filterTsStatus}}
                                                <strong class="text-info mx-3">{{getStatusDate(key)}}</strong>
                                        </h6>
                                        <p 
                                            :class="getTextColor(key)" 
                                            class="status-desc">{{status.desc}}</p>
                                    </li>
                                </ul>
                            </div>
                        </itc-card>
                    </div>
                </div>
            </div>
        </itc-card>
    </div>
</template>

<script>
import axios from 'axios'

import Card from '@/components/mix/Card'
import Modal from '@/components/mix/Modal'

import tsStatus from '@/assets/tsStatus.js'
import * as utils from '@/assets/utils/utils.js'

export default {
    data(){
        return {
            showModal: {budget: false, cancelTs: false},
            tsStatus
        }
    },
    methods: {
        getTextColor(key){
            if(this.technicalService.cancellation.isCancelled && !this.hasStatus(key))
                return 'text-danger'
            else if(!this.hasStatus(key)) return 'text-muted'
        },
        getTimelineClass(key){
            const hasStatus = this.hasStatus(key)
            if(hasStatus)
                return 'timeline-ready'
            else if(!hasStatus && this.technicalService.cancellation.isCancelled)
                return 'timeline-cancelled'
            return 'timeline-none'
        },
        getStatusDate(key){
            for(let status of this.technicalService.history)
                if(status.name == key)
                    return status.date
            return ''
        },
        hasStatus(key){ 
            for(let status of this.technicalService.history)
                if(status.name == key)
                    return true
            return false
        },
        async printOrder(){
            const resp = await axios.get(`/technical-service/print-definition?searcher=${this.technicalService._id}`)
            const newWindow = window.open({});
            //TODO: Revisar si hacer si o editarlo desde node
            newWindow.document.write(resp.data += resp.data)
            setTimeout(() => {
                newWindow.print()
                newWindow.close()
            }, 2000)
        },
        async repairTechnicalService(){
            const accept = confirm('¿Seguro que queres dar por terminado el servicio técnico?')
            if(!accept) return;
            const resp = await axios.post('/technical-service/repair', {id: this.technicalService._id})
            this.technicalService.history.push(resp.data)
        },
        async cancelTechnicalService(e){
            const reason = e.target.elements['reason'].value
            const resp = await axios.post('/technical-service/cancel', {id: this.technicalService._id, reason})
            this.technicalService.cancellation = resp.data
            this.showModal.cancelTs = false
        },
        async confirmBudget(){
            const accept = confirm('¿Seguro que queres aceptar el presupuesto?')
            if(!accept) return;
            const resp = await axios.post('/technical-service/confirm-budget', {id: this.technicalService._id})
            this.technicalService.history.push(resp.data)
        },
        async submitBudget(e){
            const budget = e.target.elements['budget'].value
            const resp = await axios.post('/technical-service/update-budget', {id: this.technicalService._id, budget})
            if(resp.data) this.technicalService.history.push(resp.data)
            this.technicalService.budget = budget
            this.$toasted.success('Presupuesto actualizado')
            this.showModal.budget = false
        },
    },
    props: {
        technicalService: Object,
    },
    components: {
        'itc-card': Card,
        'itc-modal': Modal,
    },
    filters: {
        filterTsStatus(name){
            return utils.filterTechnicalServiceStatus(name)
        },
    }
}
</script>

<style lang="scss" scoped>
.cancellation-box{
    font-size: 0.7em;
}

.status-desc{
    font-size: 0.8em;
    margin-left: 15px;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #aaaaaa;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 2;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute; 
    border-radius: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 2;
}
.timeline-ready:before{
    border: 3px solid #32f002;
}
.timeline-none:before{
    border: 3px solid #979797;
}
.timeline-cancelled:before{
    border: 3px solid #fd2626;
}
li{
    list-style: none;
}
th {
  text-align: left;
}

th, td {
    border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.2);
    padding: 6px;
}
</style>
