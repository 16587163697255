<template>
    <div class="generate-technical-service">
        <itc-modal :showModal="showModal.itemType"  @close="showModal.itemType = false">
            <div slot="header">
                <h4>Añadir tipo de producto</h4>
            </div>
            <div slot="body">
                <label> Nombre</label>
                <input ref="itemTypeName" type="text" class="form-control">
                <span class="text-danger">{{itemTypeErrorSpan}}</span>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="addItemType">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.itemType = false">Cerrar</button>
                    </div>
                </div>
            </div> 
        </itc-modal>
        <itc-modal :showModal="showModal.technician"  @close="showModal.technician = false">
            <div slot="header">
                <h4>Añadir técnico</h4>
            </div>
            <div slot="body">
                <label> Nombre</label>
                <input ref="technicianName" type="text" class="form-control">
                <span class="text-danger">{{technicianErrorSpan}}</span>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="addTechnician">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.technician = false">Cerrar</button>
                    </div>
                </div>
            </div> 
        </itc-modal>
        <itc-modal :showModal="showModal.selectUser" @close="showModal.selectUser = false">
            <div slot="header">
                <h4>Buscar cliente</h4>
            </div>
            <div slot="body">
                <label for="searchUser"> Buscar</label>
                <input ref="inputName" @input="searchUsers" type="text" id="searchUser" class="form-control" placeholder="Apellido - Nombre">
                <div class="list-group mx-4 my-3" style="margin: 0 auto">
                    <transition-group name="fade" mode="out-in">
                        <a 
                            @click.prevent="technicalService.user = user;showModal.selectUser=false"
                            v-for="(user, i) in users" :key="i / 5" 
                            class="list-group-item list-group-item-action set-pointer"> {{user.surname}} {{user.name}} - Tel: {{user.phone}}
                        </a>
                    </transition-group>
                </div>
            </div>
        </itc-modal>
        <itc-modal :showModal="showModal.createUser" @close="showModal.createUser = false">
            <div slot="header">
                <h4>Agregar cliente</h4>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <label>Nombre</label>
                        <input ref="userName" v-model.lazy="technicalService.user.name" type="text" class="form-control">
                    </div>
                    <div class="col-12 col-lg-6">
                        <label>Apellido</label>
                        <input v-model.lazy="technicalService.user.surname" type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-lg-8">
                        <label>Email</label>
                        <input v-model.lazy="technicalService.user.email" type="email" class="form-control">
                    </div>
                    <div class="col-12 col-lg-4">
                        <label>Teléfono</label>
                        <input v-model.lazy="technicalService.user.phone" type="text" class="form-control">
                    </div>
                </div>
                <span class="text-danger">{{err}}</span>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="createNewUser">OK</button>
                        <button 
                            @click="showModal.createUser = false;technicalService.user = {name: '',surname: '',email: '',phone: ''}"
                            class="modal-default-button btn btn-warning float-right mx-2">Cerrar</button>
                    </div>
                </div>
            </div> 
        </itc-modal>
        <itc-card color="#343a40" class="custom-shadow my-2">
            <div slot="header" class="text-white text-center">
                <h4>Nuevo servicio técnico</h4>
            </div>
            <div slot="body">
                <h5>Producto</h5>
                <hr>
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <label>Tipo<i @click="showModal.itemType=true;$nextTick(() => {$refs.itemTypeName.focus()})" class="mx-2 set-pointer fas fa-plus"></i></label>
                        <select v-model="technicalService.itemType" ref="productType" class="form-control">
                            <option v-for="(itemType, i) in itemTypes" :key="i" :value="itemType._id">{{itemType.name}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-4">
                        <label>Numero de serie o IMEI</label>
                        <input v-model="technicalService.serial" type="text" class="form-control">
                    </div>
                    <div class="col-12 col-lg-4">
                        <label>Descripción</label>
                        <input v-model="technicalService.description" type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-lg-4">
                        <label>Observaciones<small class="text-muted mx-2">Opcional</small></label>
                        <input v-model="technicalService.observs" type="text" class="form-control">
                    </div>
                    <div class="col-12 col-lg-4">
                        <label>Falla</label>
                        <input v-model="technicalService.failure" type="text" class="form-control">
                    </div>
                    <div class="col-12 col-lg-4">
                        <label>Técnico<i @click="showModal.technician=true;$nextTick(() => {$refs.technicianName.focus()})" class="mx-2 set-pointer fas fa-plus"></i></label>
                        <select v-model="technicalService.technician" class="form-control">
                            <option v-for="(technician, i) in technicians" :key="i" :value="technician._id">{{technician.name}}</option>
                        </select>
                    </div>
                </div>
                <h5 class="mt-3">Cliente
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button @click="openSearchUserModal" class="btn btn-info btn-sm">
                            <i class="fas fa-search"></i>
                        </button>
                        <button 
                            @click="openCreateUserModal" 
                            class="btn btn-success btn-sm">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </h5>
                <hr>
                <div class="row">
                    <div class="col-lg-4 col-12">
                        <label>Nombre</label>
                        <input :value="technicalService.user.name" type="text" class="form-control" readonly>
                    </div>
                    <div class="col-lg-4 col-12">
                        <label>Apellido</label>
                        <input :value="technicalService.user.surname" type="text" class="form-control" readonly>
                    </div>
                    <div class="col-lg-4 col-12">
                        <label>Email</label>
                        <input :value="technicalService.user.email" type="email" class="form-control" readonly>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-4 col-12">
                        <label>Teléfono</label>
                        <input :value="technicalService.user.phone" type="number" class="form-control" readonly>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <button 
                            :disabled="hasEmptyFields"
                            @click="createNewTechnicalService" class="float-right btn btn-success">Finalizar</button>
                    </div>
                </div>
            </div>
        </itc-card>
    </div>
</template>

<script>
import Card from '@/components/mix/Card'
import Modal from '@/components/mix/Modal'

import axios from 'axios'

export default {
    data(){
        return {
            technicalService: {
                itemType: '',
                serial: '',
                description: '',
                observs: '',
                failure: '',
                technician: '',
                user: {
                    name: '',
                    surname: '',
                    email: '',
                    phone: ''
                }
            },
            showModal: {selectUser: false, createUser: false, itemType: false, technician: false},
            users: [],
            itemTypes: [],
            technicians: [],
            err: '',
            itemTypeErrorSpan: '',
            technicianErrorSpan: ''
        }
    },
    computed: {
        hasEmptyFields(){
            for(let key in this.technicalService){
                if(key == 'user' && !this.technicalService['user'].name) return true
                if(this.technicalService[key] == '' && key != 'observs') return true
            }
            return false
        }
    },
    methods: {
        async addTechnician(){
            const technicianName = this.$refs.technicianName.value
            const resp = await axios.post('/technical-service/add-technician', {name: technicianName})
            this.technicianErrorSpan = resp.data.err
            if(resp.data.status == 200){
                this.technicians.push(resp.data.technician)
                this.$toasted.success('Técnico agregado')
                this.showModal.technician = false
            }
        },
        async addItemType(){
            const itemTypeName = this.$refs.itemTypeName.value
            const resp = await axios.post('/technical-service/add-item-type', {name: itemTypeName})
            this.itemTypeErrorSpan = resp.data.err
            if(resp.data.status == 200){
                this.itemTypes.push(resp.data.itemType)
                this.$toasted.success('Tipo de producto agregado')
                this.showModal.itemType = false
            }
        },
        openSearchUserModal(){
            this.showModal.selectUser = true;
            this.$nextTick(() => {
                this.$refs.inputName.focus()
            })
        },
        openCreateUserModal(){
            this.showModal.createUser = true;
            this.technicalService.user = {name: '', surname: '', email: '', phone: ''};
            this.$nextTick(() => {
                this.$refs.userName.focus()
            })
        },
        async searchUsers(event){
            const searcher = event.target.value
            if(searcher.length > 2){
                const surname = searcher.split(" ")[0]
                const name = searcher.split(" ")[1]
                const resp = await axios.get(`/user/name?name=${name}&surname=${surname}`)
                this.users = resp.data 
            }
            else
                this.users = []
        },
        async createNewUser(){
            const resp = await axios.post('/user/signUp', {user: JSON.stringify(this.technicalService.user)})
            if(resp.data.status == 500) return this.err = 'Email ya registrado'
            this.showModal.createUser = false
            this.technicalService.user = {name: '', surname: '', email: '', phone: ''}
        },
        async createNewTechnicalService(){
            const resp = await axios.post('/technical-service/create', {technicalService: JSON.stringify(this.technicalService)})
            const newWindow = window.open({});
            //TODO: Revisar si hacer si o editarlo desde node
            newWindow.document.write(resp.data += resp.data)
            this.$emit('ts-generated')
            setTimeout(() => {
                newWindow.print()
                newWindow.close()
            }, 2000)
        }
    },
    async created(){
        const resp = await axios.get('/technical-service/initial-data')
        this.itemTypes = resp.data.itemTypes
        this.technicians = resp.data.technicians
        this.$nextTick(() => {
            this.$refs.productType.focus()
        })
    },
    components: {
        'itc-card': Card,
        'itc-modal': Modal,
    }
}
</script>