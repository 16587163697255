export default {
    'in-workshop': {
        desc: 'El producto ingreso al taller'
    },
    'budgeted': {
        desc: 'Presupuesto disponible'
    },
    'in-repair': {
        desc: 'El producto esta en reparación'
    },
    'retire': {
        desc: 'El producto esta disponible para retiro'
    },
    'finished': {
        desc: 'El servicio técnico finalizo'
    },
}