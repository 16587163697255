<template>
    <div class="technical-service">
        <itc-technical-service-sidebar @changePage="changePage" :changePage="changePage"></itc-technical-service-sidebar>
        <div class="main">
            <h4 class="pt-3">Servicio técnico</h4>
            <hr>
            <transition name="fade" mode="out-in">
                <component 
                    :is="actualComponent"
                    @ts-generated="actualComponent = 'itc-search-technical-service'"/>
            </transition> 
        </div>
    </div>
</template>

<script>
import TechnicalServiceSideBar from '@/components/shared/localshop/TechnicalServiceSideBar'
import SearchTechnicalService from '@/components/localshop/technical_service/SearchTechnicalService'
import GenerateTechnicalService from '@/components/localshop/technical_service/GenerateTechnicalService'

export default {
    data(){
        return {
            actualComponent: 'itc-search-technical-service'
        }
    },
    methods: {
        changePage(page){
            this.actualComponent = page
        },
    },
    components: {
        'itc-technical-service-sidebar': TechnicalServiceSideBar,
        'itc-generate-technical-service': GenerateTechnicalService,
        'itc-search-technical-service': SearchTechnicalService,
    }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  width: 82%;
  top: 0;
  left: 16%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>