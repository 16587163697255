<template>
    <div class="searcher custom-shadow">
        <itc-card color="#343a40">
            <div slot="header" class="text-center text-white">
                <h4>Buscador</h4>
            </div>
            <div slot="body" class="row">
                <div class="col-4">
                    <div class="searchByOrderNumber">
                        <label for="numberSearcher">Por número de servicio</label>
                        <input
                            ref="serviceNumberInput" 
                            v-model="numberSearcher" 
                            @keyup.enter="searchFunction(numberSearcher, 'serviceNumber');numberSearcher = ''"
                            class="form-control" type="text">
                    </div>
                </div>
                <div class="col-4">
                    <div class="SearchByDate">
                        <label for="DateSearcher">Por fecha</label>
                        <input
                            v-model="dateSearcher" 
                            @change="searchFunction(dateSearcher, 'date')"
                            class="form-control" type="date">
                    </div>
                </div>
                <div class="col-4">
                    <div class="SearchByUser">
                        <label for="userSearcher">Por cliente</label>
                        <input
                            v-model="userSearcher"
                            placeholder="Apellido - Nombre"
                            @change="searchFunction(userSearcher, 'user');userSearcher = ''"
                            class="form-control" type="text">
                    </div>
                </div>
            </div>
        </itc-card>
    </div>
</template>

<script>
import Card from '@/components/mix/Card'

export default {
    components: {
        'itc-card': Card
    },
    data(){
        return {
            numberSearcher: '',
            dateSearcher: null,
            userSearcher: ''
        }
    },
    created(){
        this.$nextTick(() => {
            this.$refs.serviceNumberInput.focus()
        })
    },
    props: {
        searchFunction: Function
    }
}
</script>

<style lang="scss" scoped>
.custom-border{
    border-bottom: 1px solid lightgray;
}